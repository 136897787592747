<template>
  <el-dialog v-dialogDrag :title="title" :visible.sync="visible" width="600px">
    <el-form style="padding:20px" label-width="140px" class="form" ref="form" :model="form" :rules="rules">
      <el-form-item label="子级行业类目名称" prop="industryName">
        <el-input v-model="form.industryName"></el-input>
      </el-form-item>
      <el-form-item label="上级行业类目" prop="parentCode">
        <el-select style="width:100%" v-model="form.parentCode">
          <el-option v-for="item in typeList" :key="item.industryCode" :value="item.industryCode" :label="item.industryName"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="$emit('update:show',false)">取 消</el-button>
      <el-button size="small" type="primary" @click="submit" :loading="loading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    parentCode: {
      type: String,
      default: "",
    },
  },
  computed: {
    visible: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    title() {
      var title = "添加二级类目";
      if (this.form.id) {
        title = "修改二级类目";
      }
      return title;
    },
  },
  watch: {
    row(val) {
      this.initForm(val);
      console.log(this.form);
    },
  },
  data() {
    return {
      form: {},
      loading: false,
      rules: {
        industryName: [
          { required: true, message: "子级行业类目名称必填", trigger: "change" },
        ],
        parentCode: [
          { required: true, message: "上级行业类目必填", trigger: "change" },
        ],
      },
      typeList: [],
    };
  },

  created() {
    this.initForm(this.row);
    this.getType();
  },
  mounted() {},
  methods: {
    async getType() {
      var res = await this.$get("/platform-config/industry/tree");
      if (res && res.code === 0) {
        this.typeList = res.data || [];
      }
    },
    initForm(val) {
      // 判断是否拿到表单元素，重置表单
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      // 初始化form数据
      if (val.id) {
        this.form = { ...val };
      } else {
        this.form = {
          industryName: "",
          parentCode: this.parentCode,
        };
      }
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          var data = { ...this.form };

          if (data.id) {
            var res = await this.$put("/platform-config/industry", data);
          } else {
            var res = await this.$post("/platform-config/industry", data);
          }
          this.loading = false;
          if (res && res.code === 0) {
             var str = data.id ? "修改成功" : "添加成功";
            var ev = data.id ? "edit" : "add";
            this.$message.success(str);
            this.$emit(ev);
            this.$emit("update:show", false);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.editor {
  width: 100%;
  height: 350px;
}
</style>